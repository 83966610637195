<script lang="ts">
  import testimonialData from "$lib/assets/marketing/testimonial-data";
  import TestimonialCard from "./TestimonialCard.svelte";

  export let keys: string[];
</script>

<div>
  {#each keys as key}
    {#if testimonialData[key]}
      <div class="mb-8">
        <TestimonialCard {...testimonialData[key]} />
      </div>
    {/if}
  {/each}
</div>
