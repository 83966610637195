<script lang="ts">
  export let width: number = 142;
  export let height: number = 42;
</script>

<svg
  {width}
  {height}
  viewBox="0 0 142 42"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M20.447 41C31.7395 41 40.894 32.0457 40.894 21C40.894 9.95431 31.7395 1 20.447 1C9.15442 1 0 9.95431 0 21C0 32.0457 9.15442 41 20.447 41ZM12.4453 23.6094H27.6555C30.0567 23.6094 32.0033 25.556 32.0033 27.9572C32.0033 30.3584 30.0567 32.305 27.6555 32.305H12.4453V23.6094ZM23.4473 9.69531H12.4453V20.1301H23.4473C26.2087 20.1301 28.4473 17.8915 28.4473 15.1301V14.6953C28.4473 11.9339 26.2087 9.69531 23.4473 9.69531Z"
    fill="#41666F"
  />
  <path
    d="M54.5254 33.7489V10.4155H65.7031C68.5656 10.4155 70.7467 10.9711 72.2461 12.0822C73.7456 13.1711 74.4953 14.6377 74.4953 16.4822C74.4953 17.7266 74.1999 18.7933 73.6092 19.6822C73.0185 20.5489 72.2234 21.2266 71.2238 21.7155C70.2468 22.1822 69.1791 22.4155 68.0204 22.4155L68.6338 21.2155C69.9742 21.2155 71.1783 21.46 72.2461 21.9489C73.3139 22.4155 74.1545 23.1044 74.7679 24.0155C75.404 24.9266 75.7221 26.06 75.7221 27.4155C75.7221 29.4155 74.9383 30.9711 73.3707 32.0822C71.8031 33.1933 69.4744 33.7489 66.3846 33.7489H54.5254ZM58.9556 30.3489H66.112C67.7705 30.3489 69.0427 30.0822 69.9288 29.5489C70.8148 29.0155 71.2578 28.16 71.2578 26.9822C71.2578 25.8266 70.8148 24.9822 69.9288 24.4489C69.0427 23.8933 67.7705 23.6155 66.112 23.6155H58.6148V20.2489H65.226C66.7709 20.2489 67.9522 19.9822 68.7701 19.4489C69.6107 18.9155 70.031 18.1155 70.031 17.0489C70.031 15.96 69.6107 15.1489 68.7701 14.6155C67.9522 14.0822 66.7709 13.8155 65.226 13.8155H58.9556V30.3489Z"
    fill="white"
  />
  <path
    d="M91.2528 33.749V30.149L91.0143 29.3823V23.0823C91.0143 21.8601 90.6394 20.9156 89.8897 20.249C89.14 19.5601 88.004 19.2156 86.4818 19.2156C85.4595 19.2156 84.4485 19.3712 83.4489 19.6823C82.472 19.9934 81.6427 20.4267 80.9612 20.9823L79.2913 17.949C80.2682 17.2156 81.4269 16.6712 82.7673 16.3156C84.1304 15.9378 85.539 15.749 86.993 15.749C89.6284 15.749 91.6617 16.3712 93.093 17.6156C94.547 18.8378 95.274 20.7378 95.274 23.3156V33.749H91.2528ZM85.5277 33.9823C84.1645 33.9823 82.9718 33.7601 81.9494 33.3156C80.9271 32.849 80.1319 32.2156 79.564 31.4156C79.0187 30.5934 78.7461 29.6712 78.7461 28.649C78.7461 27.649 78.9846 26.749 79.4617 25.949C79.9615 25.149 80.7681 24.5156 81.8813 24.049C82.9945 23.5823 84.4712 23.349 86.3115 23.349H91.5936V26.1156H86.6182C85.1642 26.1156 84.1872 26.349 83.6874 26.8156C83.1876 27.2601 82.9377 27.8156 82.9377 28.4823C82.9377 29.2378 83.2444 29.8378 83.8578 30.2823C84.4712 30.7267 85.3232 30.949 86.4137 30.949C87.4588 30.949 88.3902 30.7156 89.2081 30.249C90.0487 29.7823 90.6508 29.0934 91.0143 28.1823L91.7299 30.6823C91.321 31.7267 90.5826 32.5378 89.5148 33.1156C88.4697 33.6934 87.1407 33.9823 85.5277 33.9823Z"
    fill="white"
  />
  <path
    d="M106.364 33.9823C104.842 33.9823 103.377 33.7934 101.968 33.4156C100.582 33.0156 99.4805 32.5378 98.6626 31.9823L100.298 28.8156C101.116 29.3267 102.093 29.749 103.229 30.0823C104.365 30.4156 105.501 30.5823 106.637 30.5823C107.977 30.5823 108.943 30.4045 109.533 30.049C110.147 29.6934 110.453 29.2156 110.453 28.6156C110.453 28.1267 110.249 27.7601 109.84 27.5156C109.431 27.249 108.897 27.049 108.238 26.9156C107.58 26.7823 106.841 26.6601 106.023 26.549C105.228 26.4378 104.422 26.2934 103.604 26.1156C102.809 25.9156 102.082 25.6378 101.423 25.2823C100.764 24.9045 100.23 24.4045 99.8212 23.7823C99.4123 23.1601 99.2078 22.3378 99.2078 21.3156C99.2078 20.1823 99.5373 19.2045 100.196 18.3823C100.855 17.5378 101.775 16.8934 102.956 16.449C104.16 15.9823 105.58 15.749 107.216 15.749C108.443 15.749 109.681 15.8823 110.931 16.149C112.18 16.4156 113.214 16.7934 114.032 17.2823L112.396 20.449C111.533 19.9378 110.658 19.5934 109.772 19.4156C108.909 19.2156 108.045 19.1156 107.182 19.1156C105.887 19.1156 104.921 19.3045 104.285 19.6823C103.672 20.0601 103.365 20.5378 103.365 21.1156C103.365 21.649 103.57 22.049 103.979 22.3156C104.388 22.5823 104.921 22.7934 105.58 22.949C106.239 23.1045 106.966 23.2378 107.761 23.349C108.579 23.4378 109.386 23.5823 110.181 23.7823C110.976 23.9823 111.703 24.2601 112.362 24.6156C113.043 24.949 113.589 25.4267 113.998 26.049C114.407 26.6712 114.611 27.4823 114.611 28.4823C114.611 29.5934 114.27 30.5601 113.589 31.3823C112.93 32.2045 111.987 32.849 110.76 33.3156C109.533 33.7601 108.068 33.9823 106.364 33.9823Z"
    fill="white"
  />
  <path
    d="M118.279 33.749V15.949H122.539V33.749H118.279ZM120.426 13.0156C119.631 13.0156 118.972 12.7712 118.45 12.2823C117.95 11.7934 117.7 11.2045 117.7 10.5156C117.7 9.80451 117.95 9.21562 118.45 8.74896C118.972 8.26007 119.631 8.01562 120.426 8.01562C121.222 8.01562 121.869 8.24896 122.369 8.71562C122.891 9.16007 123.153 9.72674 123.153 10.4156C123.153 11.149 122.903 11.7712 122.403 12.2823C121.903 12.7712 121.244 13.0156 120.426 13.0156Z"
    fill="white"
  />
  <path
    d="M133.753 33.9823C132.231 33.9823 130.766 33.7934 129.357 33.4156C127.971 33.0156 126.869 32.5378 126.051 31.9823L127.687 28.8156C128.505 29.3267 129.482 29.749 130.618 30.0823C131.754 30.4156 132.89 30.5823 134.026 30.5823C135.366 30.5823 136.332 30.4045 136.922 30.049C137.536 29.6934 137.843 29.2156 137.843 28.6156C137.843 28.1267 137.638 27.7601 137.229 27.5156C136.82 27.249 136.286 27.049 135.627 26.9156C134.969 26.7823 134.23 26.6601 133.412 26.549C132.617 26.4378 131.811 26.2934 130.993 26.1156C130.198 25.9156 129.471 25.6378 128.812 25.2823C128.153 24.9045 127.619 24.4045 127.21 23.7823C126.801 23.1601 126.597 22.3378 126.597 21.3156C126.597 20.1823 126.926 19.2045 127.585 18.3823C128.244 17.5378 129.164 16.8934 130.345 16.449C131.549 15.9823 132.969 15.749 134.605 15.749C135.832 15.749 137.07 15.8823 138.32 16.149C139.569 16.4156 140.603 16.7934 141.421 17.2823L139.785 20.449C138.922 19.9378 138.047 19.5934 137.161 19.4156C136.298 19.2156 135.434 19.1156 134.571 19.1156C133.276 19.1156 132.311 19.3045 131.674 19.6823C131.061 20.0601 130.754 20.5378 130.754 21.1156C130.754 21.649 130.959 22.049 131.368 22.3156C131.777 22.5823 132.311 22.7934 132.969 22.949C133.628 23.1045 134.355 23.2378 135.15 23.349C135.968 23.4378 136.775 23.5823 137.57 23.7823C138.365 23.9823 139.092 24.2601 139.751 24.6156C140.433 24.949 140.978 25.4267 141.387 26.049C141.796 26.6712 142 27.4823 142 28.4823C142 29.5934 141.659 30.5601 140.978 31.3823C140.319 32.2045 139.376 32.849 138.149 33.3156C136.922 33.7601 135.457 33.9823 133.753 33.9823Z"
    fill="white"
  />
</svg>
