import basisBackground from "$lib/assets/gradients/basis_testimonial_background.svg";
import codeGenBackground from "$lib/assets/gradients/codegen_testimonial_background.svg";
import rampBackground from "$lib/assets/gradients/ramp_testimonial_background.svg";
import substackBackground from "$lib/assets/gradients/substack_testimonial_background.svg";
import sunoBackground from "$lib/assets/gradients/suno_testimonial_background.svg";
import wisprBackground from "$lib/assets/gradients/wispr_testimonial_background.svg";
import BasisLogo from "$lib/assets/logos/customers/BasisLogo.svelte";
import CodeGenLogo from "$lib/assets/logos/customers/CodeGenLogo.svelte";
import RampLogo from "$lib/assets/logos/customers/RampLogo.svelte";
import SubstackLogo from "$lib/assets/logos/customers/SubstackLogo.svelte";
import SunoLogo from "$lib/assets/logos/customers/SunoLogo.svelte";
import WisprLogo from "$lib/assets/logos/customers/WisprLogo.svelte";
import sunoAuthorImg from "$lib/assets/marketing/authors/georg_kucsko.png";
import codeGenAuthorImg from "$lib/assets/marketing/authors/jay_hack.png";
import rampAuthorImg from "$lib/assets/marketing/authors/karim_atiyeh.png";
import basisAuthorImg from "$lib/assets/marketing/authors/matt_harpe.png";
import substackAuthorImg from "$lib/assets/marketing/authors/mike_cohen.png";
import wisprAuthorImg from "$lib/assets/marketing/authors/sahaj_garg.png";

type Testimonial = {
  logo: any;
  logoWidth: number;
  logoHeight: number;
  text: string;
  author: string;
  authorImg: string;
  jobTitle: string;
  fillColor?: string;
  background?: any;
};

type TestimonialData = {
  [key: string]: Testimonial;
};

const testimonialData: TestimonialData = {
  Ramp: {
    logo: RampLogo,
    logoWidth: 150,
    logoHeight: 40,
    text: "Ramp uses Modal to run some of our most data-intensive projects. Our team loves the developer experience because it allows them to be more productive and move faster. Without Modal, these projects would have been impossible for us to launch. Modal's user-friendly interface and efficient tools have truly empowered our team to navigate data-intensive tasks with ease, enabling us to achieve our project goals more efficiently.",
    author: "Karim Atiyeh",
    jobTitle: "Co-Founder & CTO",
    authorImg: rampAuthorImg,
    fillColor: "#E7F256",
    background: rampBackground,
  },
  RampShort: {
    logo: RampLogo,
    logoWidth: 150,
    logoHeight: 40,
    text: "Ramp uses Modal to run some of our most data-intensive projects. Our team loves the developer experience because it allows them to be more productive and move faster. Without Modal, these projects would have been impossible for us to launch.",
    author: "Karim Atiyeh",
    jobTitle: "Co-Founder & CTO",
    authorImg: rampAuthorImg,
    fillColor: "#E7F256",
    background: rampBackground,
  },
  Substack: {
    logo: SubstackLogo,
    logoWidth: 183,
    logoHeight: 40,
    text: "Substack recently launched a feature for AI-powered audio transcriptions. The data team picked Modal because it makes it <b>easy to write code that runs on 100s of GPUs</b> in parallel, transcribing podcasts in a fraction of the time.",
    author: "Mike Cohen",
    jobTitle: "Head of Data",
    authorImg: substackAuthorImg,
    fillColor: "#FF6719",
    background: substackBackground,
  },
  Suno: {
    logo: SunoLogo,
    logoWidth: 140,
    logoHeight: 40,
    text: "Suno has developed proprietary state-of-the-art models that generate music and speech using AI. We chose Modal as our infrastructure provider for inference and parallel data processing. Modal's superb developer experience enables our team to ship new models to production quickly, and with and confidence we'll scale to thousands of simultaneous users.",
    author: "Georg Kucsko",
    jobTitle: "Co-Founder",
    authorImg: sunoAuthorImg,
    fillColor: "#FFFFFF",
    background: sunoBackground,
  },
  Wispr: {
    logo: WisprLogo,
    logoWidth: 144,
    logoHeight: 38,
    text: "Modal has made it incredibly easy for a team of researchers and scientists to deploy data science and data processing pipelines on cloud workloads. It means that it’s possible for an R&D team to own the software that scientists are developing, as opposed to handing it off to a team of engineers working on infrastructure.",
    author: "Sahaj Garg",
    jobTitle: "CTO",
    authorImg: wisprAuthorImg,
    fillColor: "#FFFFFF",
    background: wisprBackground,
  },
  CodeGen: {
    logo: CodeGenLogo,
    logoWidth: 162,
    logoHeight: 40,
    text: "Using Modal, Codegen has been able to move at lightning speed with full-stack AI development. The product is designed with developer experience front and center, and my team is incredibly happy and productive having it as part of our arsenal.",
    author: "Jay Hack",
    jobTitle: "Founder & CEO",
    authorImg: codeGenAuthorImg,
    background: codeGenBackground,
  },
  Basis: {
    logo: BasisLogo,
    logoWidth: 142,
    logoHeight: 41,
    text: "Basis is an AI platform for accountants. We use Modal to securely run LLM-augmented code on a large scale. Modal’s powerful primitives like sandboxes and file systems have allowed us to focus on our core competencies without having to waste time rolling our own infrastructure.",
    author: "Matt Harpe",
    jobTitle: "Co-Founder, Basis",
    authorImg: basisAuthorImg,
    background: basisBackground,
  },
};

export default testimonialData;
