<script lang="ts">
  export let logo: any;
  export let logoWidth: number;
  export let logoHeight: number;
  export let text: string;
  export let author: string;
  export let jobTitle: string;
  export let authorImg: string;
  export let fillColor: string | undefined = undefined;
  export let background: any = undefined;
</script>

<div
  class="outline outline-1 outline-white/10 -outline-offset-1 rounded-lg flex flex-col md:flex-row overflow-hidden"
>
  <div
    class="p-6 md:p-8 md:pl-16 flex flex-col justify-center md:items-start gap-6 md:min-w-[328px]"
    style="background-image: url({background}); background-size: cover; background-position: center;"
  >
    <div class="flex justify-center items-center" style:fill={fillColor}>
      <svelte:component this={logo} width={logoWidth} height={logoHeight} />
    </div>
    <div class="flex items-center justify-center gap-3">
      <img
        loading="lazy"
        class="rounded-full w-8 h-8 overflow-hidden"
        src={authorImg}
        alt={author}
      />
      <div class="text-sm flex flex-col">
        <div class="font-bold text-white/80">{author}</div>
        <div class="text-white/60">{jobTitle}</div>
      </div>
    </div>
  </div>
  <div
    class="type-subtitle-md p-6 sm:p-8 bg-white/5 border-l border-white/10 text-white/80 flex items-center"
  >
    <p class="-indent-1.5 ml-1"><em>“{@html text}”</em></p>
  </div>
</div>
