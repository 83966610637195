<script lang="ts">
  export let width: number = 106;
  export let height: number = 29;
</script>

<svg {width} {height} viewBox="0 0 106 29" xmlns="http://www.w3.org/2000/svg">
  <g id="wispr" clip-path="url(#clip0_105_8815)">
    <path
      id="Vector"
      d="M2.77671 0.75C2.28335 0.75 1.8102 0.945986 1.46135 1.29484C1.11249 1.6437 0.916504 2.11685 0.916504 2.61021L0.916504 24.6664C0.916504 25.1598 1.11249 25.6329 1.46135 25.9818C1.8102 26.3306 2.28335 26.5266 2.77671 26.5266C3.27007 26.5266 3.74322 26.3306 4.09208 25.9818C4.44093 25.6329 4.63692 25.1598 4.63692 24.6664V2.61021C4.63692 2.11685 4.44093 1.6437 4.09208 1.29484C3.74322 0.945986 3.27007 0.75 2.77671 0.75Z"
    />
    <path
      id="Vector_2"
      d="M13.954 4.96338C13.4606 4.96338 12.9874 5.15936 12.6386 5.50822C12.2897 5.85708 12.0938 6.33023 12.0938 6.82359V20.2751C12.0938 20.7685 12.2897 21.2416 12.6386 21.5905C12.9874 21.9393 13.4606 22.1353 13.954 22.1353C14.4473 22.1353 14.9205 21.9393 15.2693 21.5905C15.6182 21.2416 15.8142 20.7685 15.8142 20.2751V6.82677C15.8146 6.58221 15.7668 6.33998 15.6735 6.11392C15.5802 5.88786 15.4432 5.68242 15.2704 5.50935C15.0977 5.33627 14.8925 5.19897 14.6666 5.10528C14.4407 5.0116 14.1985 4.96338 13.954 4.96338Z"
    />
    <path
      id="Vector_3"
      d="M8.3646 12.5548C7.87124 12.5548 7.39809 12.7508 7.04924 13.0997C6.70038 13.4485 6.50439 13.9217 6.50439 14.415V22.0816C6.50439 22.575 6.70038 23.0481 7.04924 23.397C7.39809 23.7458 7.87124 23.9418 8.3646 23.9418C8.85796 23.9418 9.33111 23.7458 9.67997 23.397C10.0288 23.0481 10.2248 22.575 10.2248 22.0816V14.415C10.2248 13.9217 10.0288 13.4485 9.67997 13.0997C9.33111 12.7508 8.85796 12.5548 8.3646 12.5548Z"
    />
    <path
      id="Vector_4"
      d="M25.1278 0.75C24.6344 0.75 24.1613 0.945986 23.8124 1.29484C23.4636 1.6437 23.2676 2.11685 23.2676 2.61021V24.6664C23.2676 25.1598 23.4636 25.6329 23.8124 25.9818C24.1613 26.3306 24.6344 26.5266 25.1278 26.5266C25.6211 26.5266 26.0943 26.3306 26.4432 25.9818C26.792 25.6329 26.988 25.1598 26.988 24.6664V2.61021C26.988 2.11685 26.792 1.6437 26.4432 1.29484C26.0943 0.945986 25.6211 0.75 25.1278 0.75Z"
    />
    <path
      id="Vector_5"
      d="M19.5399 12.5547C19.0465 12.5547 18.5734 12.7507 18.2245 13.0995C17.8757 13.4484 17.6797 13.9215 17.6797 14.4149V22.0815C17.6797 22.5749 17.8757 23.048 18.2245 23.3969C18.5734 23.7457 19.0465 23.9417 19.5399 23.9417C20.0333 23.9417 20.5064 23.7457 20.8553 23.3969C21.2041 23.048 21.4001 22.5749 21.4001 22.0815V14.4149C21.4001 13.9215 21.2041 13.4484 20.8553 13.0995C20.5064 12.7507 20.0333 12.5547 19.5399 12.5547Z"
    />
    <path
      id="Vector_6"
      d="M54.6217 17.1707L50.6969 4.96484H47.4765L43.5518 17.1707L39.7901 4.96484H35.5132L41.8466 23.9374H45.0948L49.0458 11.7578L53.0246 23.9374H56.2458L62.5244 4.96484H58.3294L54.6217 17.1707Z"
    />
    <path
      id="Vector_7"
      d="M65.4678 4.58545C64.1958 4.58545 63.1401 5.64116 63.1401 6.88607C63.1401 7.5034 63.3854 8.09544 63.8219 8.53196C64.2584 8.96848 64.8505 9.21371 65.4678 9.21371C66.0851 9.21371 66.6772 8.96848 67.1137 8.53196C67.5502 8.09544 67.7954 7.5034 67.7954 6.88607C67.7946 5.64116 66.7397 4.58545 65.4678 4.58545Z"
    />
    <path
      id="Vector_8"
      d="M72.7298 14.4646C72.7298 13.8422 73.3801 13.5441 74.2458 13.5441C75.4637 13.5441 76.1943 14.1403 76.2483 14.816H80.0951C79.77 11.8659 77.1991 10.377 74.3301 10.377C71.0556 10.377 69.0523 12.2713 69.0523 14.7341C69.0523 19.6875 76.525 17.7391 76.525 19.9308C76.525 20.5532 75.8755 20.9324 74.7387 20.9324C73.4667 20.9324 72.5192 20.337 72.4381 19.4435H68.7812C69.0515 22.4476 71.4603 24.2069 74.9247 24.2069C77.9289 24.2069 80.2295 22.3395 80.2295 19.9038C80.2271 14.3565 72.7298 16.5212 72.7298 14.4646Z"
    />
    <path
      id="Vector_9"
      d="M89.7608 10.3779C87.9197 10.3779 86.4856 11.1355 85.5658 12.5164V10.649H81.75V28.3218H85.5658V22.0702C86.4856 23.4503 87.9197 24.2079 89.7608 24.2079C93.1974 24.2079 95.9313 21.0956 95.9313 17.2798C95.9313 13.464 93.1974 10.3779 89.7608 10.3779ZM88.8943 20.5534C87.0269 20.5534 85.5658 19.1463 85.5658 17.279C85.5658 15.4387 87.0269 14.0037 88.8943 14.0037C89.3267 14.0002 89.7555 14.0823 90.156 14.2453C90.5565 14.4083 90.9207 14.6489 91.2277 14.9534C91.5347 15.2579 91.7784 15.6202 91.9447 16.0193C92.111 16.4185 92.1966 16.8466 92.1966 17.279C92.1966 19.1201 90.7346 20.5542 88.8943 20.5542V20.5534Z"
    />
    <path
      id="Vector_10"
      d="M104.644 10.3779C103.156 10.3779 101.992 11.0274 101.207 12.1642V10.649H97.3911V23.9376H101.207V17.3879C101.207 14.9235 102.696 13.8153 104.536 13.8153C105.002 13.8251 105.465 13.8886 105.916 14.0045V10.5401C105.483 10.459 105.051 10.3779 104.644 10.3779Z"
    />
    <path id="Vector_11" d="M67.3881 10.6484H63.5723V23.937H67.3881V10.6484Z" />
  </g>
  <defs>
    <clipPath id="clip0_105_8815">
      <rect width="105" height="27.5716" transform="translate(0.916504 0.75)" />
    </clipPath>
  </defs>
</svg>
